import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { log, send } from "../Terminal/ble";
import Button from "@material-ui/core/Button";
import { Icon, IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { autoComplete } from "./commands";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
    },
    prompt: {
      justifyContent: "space-between",
      display: "flex",
      marginBottom: 1,
      borderRadius: 5,
      alignItems: "center",
      cursor: "pointer",
    },
    button: {
      margin: theme.spacing(1),
    },
    playButton: {
      padding: 0,
      margin: 0,
      borderStyle: "none",
      borderWidth: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
      outline: "none",
    },
  })
);
type CommandPromptProps = {
  commands: Array<string>;
};
const CommandPrompt = ({ commands }: CommandPromptProps) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  // const [promptCommands, setPromptCommands] = React.useState<Array<string>>(
  //   commands
  // );
  const sendCommandHandler = (index: number) => {
    setSelectedIndex(index);
    let inputValue = (document.getElementById(
      "row" + index
    ) as HTMLInputElement).value;
    if (inputValue.length > 0) {
      send(inputValue);
      log(inputValue + "\r\n");
    } else {
      log("Command Not Sent - Empty value" + "\r\n");
    }
  };
  commands = [...commands, "Type your command here..."];

  return (
    <div id={"commands-from-file"} className={classes.container}>
      {commands.map((line, index) => {
        const selected = selectedIndex === index;
        if (!line.trim().length) return null;
        const placeholderColor = selected
          ? "place_selected"
          : "place_not_selected";
        return (
          <div key={index} className={classes.prompt}>
            {/*<input*/}
            {/*  id={"row" + index}*/}
            {/*  key={line + index}*/}
            {/*  type="text"*/}
            {/*  style={{*/}
            {/*    flex: 1,*/}
            {/*    backgroundColor: selected ? "rgba(63, 81, 181, 1)" : "white",*/}
            {/*    borderWidth: 0,*/}
            {/*    borderRadius: 5,*/}
            {/*    cursor: "pointer",*/}
            {/*    color: selected ? "#fff" : "#000",*/}
            {/*    margin: 0,*/}
            {/*    fontSize: 18,*/}
            {/*    padding: 10,*/}
            {/*    outline: "none",*/}
            {/*  }}*/}
            {/*  onKeyDown={(e) => {*/}
            {/*    if (e.key === "Enter") {*/}
            {/*      sendCommandHandler(index);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  placeholder={index === commands.length - 1 ? line : undefined}*/}
            {/*  className={"LoNotSensitive" + " " + placeholderColor}*/}
            {/*  defaultValue={*/}
            {/*    index !== commands.length - 1 ? line.trim() : undefined*/}
            {/*  }*/}
            {/*  onSelect={() => {*/}
            {/*    setSelectedIndex(index);*/}
            {/*  }}*/}
            {/*/>*/}
            <Autocomplete
              freeSolo
              id={"row" + index}
              key={line + index}
              disableClearable
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendCommandHandler(index);
                }
              }}
              placeholder={index === commands.length - 1 ? line : undefined}
              className={"LoNotSensitive" + " " + placeholderColor}
              defaultValue={
                index !== commands.length - 1 ? line.trim() : undefined
              }
              onSelect={() => {
                setSelectedIndex(index);
              }}
              style={{ flex: 1 }}
              selectOnFocus
              options={autoComplete}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                );
              }}
            />
            <Button
              variant="contained"
              size="small"
              color={"primary"}
              onClick={(e) => sendCommandHandler(index)}
              className={classes.button}
              endIcon={<PlayArrowIcon />}
            >
              Send
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default CommandPrompt;
