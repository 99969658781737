import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Card, TextField } from "@material-ui/core";
import { getUnitName } from "./ble";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "98%",
      alignContent: "canter",
      margin: 10,
      padding: 5,
      flex: 1,
      resize: "vertical",
      minHeight: 400,
      maxHeight: 600,
    },
    terminal: {
      width: "100%",
      height: "100%",
      flex: 1,
      backgroundColor: "#fff",
      marginBottom: 5,
      textAlign: "left",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      fontSize: 15,
    },
    textField: {
      flex: 1,
      color: "#000",
      margin: 10,
      maxHeight: 200,
    },
    button: {
      width: "fit-content",
      backgroundColor: "#70c4b5",
      marginRight: 10,
      justifyContent: "center",
      fontWeight: 700,
      cursor: "pointer",
    },
  })
);
type TerminalProps = {};
const TerminalComponent = ({}: TerminalProps) => {
  const classes = useStyles();
  const [saveToFileValue, setToFileValue] = React.useState<BlobPart>("");

  const handleSaveToFile = () => {
    const element = document.createElement("a");
    const terminal = document.getElementById("terminal");
    if (terminal) {
      const file = new Blob([terminal.innerText], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = getUnitName() + ".txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };
  return (
    <Card className={classes.card}>
      <div id="terminal" className={classes.terminal} />
      <div>
        <Button
          variant="contained"
          component="label"
          className={classes.button}
          style={{ backgroundColor: "#5dd038" }}
          onClick={handleSaveToFile}
        >
          Save To File
        </Button>
        <Button
          variant="contained"
          component="label"
          className={classes.button}
          style={{ backgroundColor: "#e5778a" }}
          onClick={() => {
            const terminal = document.getElementById("terminal");
            if (terminal) {
              terminal.innerText = "";
            }
          }}
        >
          Clear
        </Button>
      </div>
    </Card>
  );
};

export default TerminalComponent;
