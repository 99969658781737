import "./App.css";
import * as React from "react";
import { AppBar, LoadFile, Terminal } from "./components";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      display: "flex",
      padding: 10,
    },
  })
);
function App() {
  const classes = useStyles();

  return (
    <div className="App">
      <React.Fragment>
        <CssBaseline />
        <AppBar />
        <Container maxWidth="lg" className={classes.container}>
          <LoadFile />
          <Terminal />
        </Container>
      </React.Fragment>
    </div>
  );
}

export default App;
