export const dfuSecureService = "8ec90001-f315-4f60-9fb8-838830daea50";
export const bleNusServiceUUID = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
export const bleNusCharRXUUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
export const bleNusCharTXUUID = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";
export let connected = false;
export let bleDevice: any;

const MTU = 247;
let nusService: any;
let rxCharacteristic: any;
let txCharacteristic: any;
export const disconnectUnit = () => {
  if (bleDevice && bleDevice.gatt.connected) {
    bleDevice.gatt.disconnect();
  }
};

export const log = (value: string) => {
  const terminal = document.getElementById("terminal");
  if (terminal) {
    terminal.innerText +=
      ">> " + new Date().toLocaleString("en-GB") + " " + value;
    terminal.scrollTop = terminal.scrollHeight;
  }
};
export const send = (value: string) => {
  nusSendString(value + "\r\n");
};
function setConnButtonState(enabled: boolean) {
  //@ts-ignore
  let clientConnectButton = document.getElementById("clientConnectButton");
  if (enabled && clientConnectButton) {
    //@ts-ignore
    clientConnectButton.innerHTML = "Disconnect";
    clientConnectButton.style.backgroundColor = "red";
    clientConnectButton.onclick = disconnectUnit;
  } else if (clientConnectButton) {
    clientConnectButton.innerHTML = "Connect";
    clientConnectButton.style.backgroundColor = "transparent";
    clientConnectButton.onclick = connect;
  }
}
function onDisconnected() {
  connected = false;
  const unitName = document.getElementById("unit_name");
  if (unitName) unitName.innerText = bleDevice.name + " Disconnected.";
  log(bleDevice.name + " Disconnected." + "\r\n");
  setConnButtonState(false);
}
function handleNotifications(event: any) {
  console.log("notification");
  let value = event.target.value;
  // Convert raw data bytes to character values and use these to
  // construct a string.
  let str = "";
  for (let i = 0; i < value.byteLength; i++) {
    str += String.fromCharCode(value.getUint8(i));
  }
  log(str);
}
function sendNextChunk(a: Uint8Array) {
  let chunk = a.slice(0, MTU);
  rxCharacteristic.writeValue(chunk).then(function () {
    if (a.length > MTU) {
      sendNextChunk(a.slice(MTU));
    }
  });
}
function nusSendString(s: string) {
  if (bleDevice && bleDevice.gatt.connected) {
    console.log("send: " + s);
    let val_arr = new Uint8Array(s.length);
    for (let i = 0; i < s.length; i++) {
      let val = s[i].charCodeAt(0);
      val_arr[i] = val;
    }
    sendNextChunk(val_arr);
  } else {
    console.log("Not connected to a device yet.");
  }
}
export const connect = () => {
  let NavigatorObject: any = window.navigator;

  if (!NavigatorObject || !NavigatorObject.bluetooth) {
    console.log(
      "WebBluetooth API is not available.\r\n" +
        "Please make sure the Web Bluetooth flag is enabled."
    );
    log(
      "WebBluetooth API is not available on your browser.\r\n" +
        "Please make sure the Web Bluetooth flag is enabled."
    );
    return;
  }
  NavigatorObject.bluetooth
    .requestDevice({
      filters: [
        { services: [bleNusServiceUUID] },
        { services: [dfuSecureService] },
      ],
    })
    .then((device: any) => {
      bleDevice = device;
      console.log("Found " + device.name);
      console.log("Connecting to GATT Server...");
      bleDevice.addEventListener("gattserverdisconnected", onDisconnected);
      return device.gatt.connect();
    })
    .then((server: any) => {
      console.log("Locate NUS service");
      return server.getPrimaryService(bleNusServiceUUID);
    })
    .then((service: any) => {
      nusService = service;
      console.log("Found NUS service: " + service.uuid);
    })
    .then(() => {
      console.log("Locate RX characteristic");
      return nusService.getCharacteristic(bleNusCharRXUUID);
    })
    .then((characteristic: any) => {
      rxCharacteristic = characteristic;
      console.log("Found RX characteristic");
    })
    .then(() => {
      console.log("Locate TX characteristic");
      return nusService.getCharacteristic(bleNusCharTXUUID);
    })
    .then((characteristic: any) => {
      txCharacteristic = characteristic;
      console.log("Found TX characteristic");
    })
    .then(() => {
      console.log("Enable notifications");
      return txCharacteristic.startNotifications();
    })
    .then(() => {
      console.log("Notifications started");
      txCharacteristic.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
      connected = true;
      log(bleDevice.name + " Connected." + "\r\n");
      const unitName = document.getElementById("unit_name");
      if (unitName) unitName.innerText = bleDevice.name + " Connected";

      nusSendString("\r");
      setConnButtonState(true);
    })
    .catch((error: any) => {
      console.log("" + error);
      log(error + "\r\n");
      if (bleDevice && bleDevice.gatt.connected) {
        bleDevice.gatt.disconnect();
      }
    });
};

export const getUnitName = () => {
  if (bleDevice) 
    return bleDevice.name;
};
