import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { TextField, Button, Card } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import CommandPrompt from "../CommandPrompt/CommandPrompt";
import ClearAllTwoToneIcon from "@material-ui/icons/ClearAllTwoTone";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { log, send } from "../Terminal/ble";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      flex: 1,
      color: "#000",
      margin: 10,
      maxHeight: 200,
    },
    classContainer: {
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
    },
    button: {
      width: "fit-content",
      marginRight: 10,
      justifyContent: "center",
      fontWeight: 700,
      cursor: "pointer",
      fontSize: 12,
      padding: 6,
    },
    fileName: {
      flex: 1,
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "98%",
      margin: 10,
      padding: 5,
      resize: "vertical",
      overflow: "auto",
      flex: 1,
    },
    commandPrompt: {
      borderWidth: 1,
      lineHeight: 2,
      textAlign: "left",
      color: "white",
      borderStyle: "solid",
      padding: 5,
      overflowY: "scroll",
      minHeight: "60%",
      backgroundColor: "white",
    },
  })
);

export default function SimpleContainer() {
  const [fileContent, setFileContent] = React.useState<
    string | ArrayBuffer | null | undefined
  >("");

  const classes = useStyles();
  let fileReader = new FileReader();

  const [commands, setCommands] = React.useState<Array<string>>([]);
  const [interval, setIntervalHandler] = React.useState<null>(null);
  const handleFileReader: any = (value: any) => {
    setCommands(value?.target?.result.split("\n"));
  };
  fileReader.addEventListener("loadend", handleFileReader);
  const handleFileChange = (file: any) => fileReader.readAsText(file);
  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };
  return (
    <Card className={classes.card}>
      <div
        style={{
          display: "flex",
        }}
      >
        <Button
          variant="contained"
          component="label"
          color="primary"
          className={classes.button}
        >
          <PublishIcon />
          <input
            className={"LoNotSensitive"}
            type="file"
            style={{ display: "none" }}
            onClick={onInputClick}
            onChange={(e) => {
              if (e && e.target && e.target.files)
                handleFileChange(e?.target?.files[0]);
            }}
          />
          LOAD FILE
        </Button>
        <Button
          variant="contained"
          color="primary"
          component="label"
          className={classes.button}
          onClick={() => {
            const commandCopy = [...commands];
            const intervalHandle = setInterval(() => {
              const command = commandCopy.shift();
              if (command && command.length > 0) {
                send(command);
                log(command + "\r\n");
              }
            }, 2000);
            //@ts-ignore
            setIntervalHandler(intervalHandle);
          }}
        >
          <ClearAllTwoToneIcon />
          SEND ALL
        </Button>
        <Button
          variant="contained"
          color="primary"
          component="label"
          className={classes.button}
          onClick={() => {
            //@ts-ignore
            clearInterval(interval);
          }}
        >
          <CancelRoundedIcon style={{ marginRight: 3 }} />
          KILL SEND
        </Button>
      </div>

      <p id="file-content" className={classes.commandPrompt}>
        <CommandPrompt commands={commands} />
      </p>
    </Card>
  );
}
